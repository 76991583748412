import React from "react";
import { useHistory } from "react-router";
import { Url } from "_constants";
import { Faq } from "_components";

interface ICard {
  icon: string;
  title: string;
  description: string | React.ReactNode;
}

const PreventableSection = () => {
  return (
    <div className="row mt-5 mx-2 text-center preventable-section align-items-end">
      <div className="col-auto col-lg-6 text-start px-lg-5">
        <div className="title strong dark-blue align-bottom">Colon Cancer is</div>
        <div className="strikethrough dark-gray large-text-1">The #2 Cause of Cancer Death</div>
        <div className="large-text-2 strong orange mb-5">Preventable!</div>
      </div>
      <div className="col-auto col-lg-6">
        <div className="couple-image">
          <img src="/images/couple.png" alt="Couple" />
        </div>
      </div>
    </div>
  );
};

const FeatureCard: React.FunctionComponent<ICard> = ({ icon, title, description }: ICard) => {
  return (
    <div className="step-card">
      <div className={`step-card-icon ${icon}`}></div>
      <div className="medium-text-2 my-5 mb-3 strong title">{title}</div>
      <div className="step-card-description small-text-2 gray">{description}</div>
    </div>
  );
};

const DigitalResultCard: React.FunctionComponent<ICard> = ({ icon, title, description }: ICard) => {
  return (
    <div className="container h-100">
      <div className="row step-card digital-result-card">
        <div className="col-xs-12 col-sm-12 col-lg-4  ">
          <div className={`step-card-icon ${icon}`}></div>
        </div>
        <div className="col-xs-12 col-sm-12 col-lg-8">
          <div className="container">
            <div className="row">
              <div className="col medium-text-2 dark-blue text-center text-md-start text-lg-start text-xl-start strong mb-3 mt-3">
                {title}
              </div>
            </div>
            <div className="row">
              <div className="col small-text-1 text-center text-md-start text-lg-start text-xl-start">{description}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Envelope = () => {
  return (
    <>
      <div className="envelope ">
        <img src="/images/Envelop.png" alt="Convena Health Test Kit" />
      </div>
      <div className="rounded-box small-text-3 dark-blue fsa shadow">
        <span>US Licensed Physicians</span>
      </div>
    </>
  );
};

const Jumbotron: React.FunctionComponent<{ onClick: any }> = ({ onClick }) => {
  return (
    <div className="row jumbotron">
      <div className="col-auto col-md-6 px-lg-5">
        <div className="mb-4 text-end text-md-start text-lg-start text-xl-start">
          <span className="recommended small-text-4">Physician Recommended</span>
        </div>
        <p className="text-center text-md-start text-lg-start dark-blue">
          <span className="title strong">Prevent Cancer</span>
          <br />
          <span className="medium-text-1 unset-line-height">
            with Convena Health's <br />
          </span>
          <span className="medium-text-1 unset-line-height fw-bold">Virtual Preventive Health Visits</span>
        </p>

        <div className="d-xs-inline-block d-sm-inline-block d-md-none d-lg-none d-xl-none mb-2">
          <Envelope />
        </div>

        <div>
          <div className="row mt-5">
            <div className="dark-gray col-12 text-xs-center text-center text-md-start text-lg-start text-xl-start">
              <span className="medium-text-1 strong">
                <span className="orange">FREE</span> and <span className="orange">NO CO-PAY</span> with qualifying health
                insurance
              </span>
            </div>

            <div className="text-center mt-3">
              <button className="btn btn-cta small-text-5 my-3 btn-activate-home" onClick={onClick}>
                ACTIVATE KIT
              </button>
            </div>
          </div>

          {/*
          <div className="row my-2">
            <div className="col-12 col-md-6 text-center text-md-start text-lg-start text-xl-start mb-3">
              <span className="rounded-box small-text-3 dark-blue">Free Shipping</span>
            </div>
          </div>
           */}
        </div>
      </div>

      <div className="col-auto col-md-6 panel-2 px-lg-5">
        <div className="rounded-box small-text-4 mx-3 text-center text-md-start text-lg-start text-xl-start">
          <span className="dark-blue">Colon Cancer is</span> <span className="light-blue">America’s #2</span>{" "}
          <span className="dark-blue">cause of cancer death. Early Diagnosis is the key to a good outcome.</span>
        </div>

        <div className="d-none d-md-inline-block d-lg-inline-block d-xl-inline-block">
          <Envelope/>
        </div>
      </div>
    </div>
  );
};

const HowItWorksSection = () => {
  return (
    <>
      {/* HOW IT WORKS */}
      <div className="row mt-5 mx-lg-5 text-center">
        <div className="col orange medium-text-1 strong">HOW IT WORKS</div>
      </div>
      <div className="row my-5">
        <div className="col-auto col-sm-12 col-md-4 mb-3">
          <FeatureCard
            icon="step-1-icon"
            title="1. Online Doctor Visit"
            description="Our doctor will meet with you via video for a full preventive care visit. They will evaluate and prescribe all appropriate tests, including the Colon Cancer screening, that are needed to make sure you are getting the very best preventive care."
          />
        </div>
        <div className="col-auto col-sm-12 col-md-4 mb-3">
          <FeatureCard
            icon="step-2-icon"
            title="2. Collect sample at home"
            description="Follow the included instructions or watch the video on how to collect your stool sample onto the test card. Then, put the
            test card in the provided envelope and mail it back to us with our prepaid shipping."
          />
        </div>

        <div className="col-auto col-sm-12 col-md-4 mb-3">
          <FeatureCard
            icon="step-3-icon"
            title="3. Receive results online"
            description="A few days after sending back your test card, you will be able to login to ConvenaHealth.com to view your test
            results on our secure, private platform."
          />
        </div>
      </div>
    </>
  );
};

const AcgRecommendsSection = () => {
  return (
    <div className="row mt-5 text-center acg-recommend-container">
      <div className="col col-12">
        <div className="acg-recommend">
          <div className="container py-3">
            <div className="row text d-flex align-items-center">
              <div className="col-lg-3">
                <div className="home-test-kit-image">
                  <img src="/images/Envelop.png" alt="Convena Health Test Kit" />
                </div>
              </div>

              <div className="col-lg-9">
                The <span className="strong">American College</span> of <span className="strong">Gastroenterology</span>{" "}
                recommends an annual home stool test for Americans <span className="strong">45 years</span> of age and older.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CertifiedLabSection = () => {
  return (
    <div className="row my-5">
      <div className="col-auto col-md-4 mb-3">
        <FeatureCard
          icon="certified-lab-icon"
          title="Dedicated Lab"
          description="We use our own lab, which is focused on processing FIT tests. We've developed special processes to make sure this important test is properly performed and that results are double-checked for accuracy."
        />
      </div>
      <div className="col-auto col-md-4 mb-3">
        <FeatureCard
          icon="physician-oversight-icon"
          title="US Licensed Physicians"
          description="All Convena physicians are licensed in the US and your state to ensure the highest quality patient care."
        />
      </div>
      <div className="col-auto col-md-4 mb-3">
        <FeatureCard
          icon="private-secure-icon"
          title="Private and Secure Data"
          description={
            <>
              Convena is HIPAA-compliant and takes our patients' privacy very seriously. We use state-of-the-art encryption and
              storage to make sure your data is safe.
              <br />
              <br />
              We will NEVER sell your data.
            </>
          }
        />
      </div>
    </div>
  );
};

const DigitalResultsSection = () => {
  return (
    <div className="row my-5 digital-results-section">
      <div className="col">
        <div className="container py-3 p-lg-5 p-md-1 p-sm-1 p-xs-1">
          <div className="row mt-lg-5 mx-lg-5 text-center ">
            <div className="col orange medium-text-1 strong">DIGITAL RESULTS</div>
          </div>
          <div className="row mx-lg-5 mb-lg-5 text-center my-2">
            <div className="col black large-text-3 strong">Results you can understand sent to your device</div>
          </div>

          <div className="row px-lg-5">
            <div className="col-auto col-lg-6 mb-lg-5 mb-3">
              <div className="digital-results mt-lg-5 mx-lg-5">
                <div className="mx-lg-3 mt-5 mt-5">
                  <img src="/images/laptop.png" alt="Digital Results" />
                </div>
              </div>
            </div>

            <div className="col-auto col-lg-6 mb-lg-5 mb-3">
              <DigitalResultCard
                icon="easy-to-understand-icon"
                title="Easy"
                description="Our goal is to present your results in a way that you can easily understand. We also provide physician-reviewed and approved relevant health information."
              />
            </div>
          </div>

          <div className="row px-lg-5">
            <div className="col-auto col-lg-6 mb-lg-5 mb-3">
              <DigitalResultCard
                icon="personalized"
                title="For You"
                description="Each test result is provided to you in the context of your personal medical history.  Individual people have unique considerations."
              />
            </div>

            <div className="col-auto col-lg-6 mb-lg-5 mb-3">
              <DigitalResultCard
                icon="actionable"
                title="Next Steps"
                description="It's important that you follow up with your physician if there is an abnormal test result. We are here to help!"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FaqSection = () => {
  return (
    <>
      <div id="faq" className="row mt-5 mx-5 text-center">
        <div className="col orange medium-text-1 strong">FAQ</div>
      </div>
      <div className="row mx-5 text-center">
        <div className="col black large-text-3 strong">Frequently Asked Questions</div>
      </div>

      <div className="row my-5 faq-section">
        <div className="col-auto col-lg-6 doc-image">
          <img src="/images/FAQ-illustration.png" alt="Convena Health FAQ" />
        </div>
        <div className="col-auto col-lg-6">
          <Faq />
        </div>
      </div>
    </>
  );
};

const BottomActivationSection: React.FunctionComponent<{ onClick: any }> = ({ onClick }) => {
  return (
    <div className="row bottom-activation-section py-3 p-lg-5">
      <div className="col p-lg-5 text-center text-md-start text-lg-start text-xl-start">
        <div className="container">
          <div className="row">
            <div className="col-auto col-lg-6">
              <div className="title strong dark-blue">
                At-home <span className="light-blue strong">Colon Cancer</span> Screening Test
              </div>
              <p className="small-text-3 dark-gray">
                Screens for the presence of blood in your stool to help detect early signs of colon cancer
              </p>
              {/*
              <button className="btn btn-cta small-text-5" onClick={onClick}>
                Buy Now
              </button>
              */}
            </div>

            <div className="col-auto col-lg-6 mt-3">
              <div className="envelope">
                <img src="/images/Envelop.png" alt="Convena Health Test Kit" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const HomePage: React.FunctionComponent = () => {
  const history = useHistory();

  const onClickActivate = () => {
    history.push(Url.CUSTOMER_ACTIVATION);
  };
  const onBuyNow = () => {
    history.push(Url.CUSTOMER_ORDER);
  };

  return (
    <div className="my-2">
      <Jumbotron onClick={onClickActivate} />

      <HowItWorksSection />

      <AcgRecommendsSection />

      <PreventableSection />

      <CertifiedLabSection />

      <DigitalResultsSection />

      <FaqSection />

      <BottomActivationSection onClick={onBuyNow} />
    </div>
  );
};

export {
  PreventableSection,
  FeatureCard,
  DigitalResultCard,
  Envelope,
  HowItWorksSection,
  AcgRecommendsSection,
  CertifiedLabSection,
  DigitalResultsSection,
  FaqSection,
  HomePage,
};
